$font-stack: Calibri, Verdana, Arial, sans-serif;
$font-title-stack: Calibri, Trebuchet MS, Arial, sans-serif;
$root-font-size: 16px;

@mixin fontSettings($fontName){
    @if $fontName == "headingXL"{
        font-family: $font-title-stack;
        font-size:3rem;
        font-weight:700;
        line-height: 3.2rem;
    }
    @if $fontName == "headingL"{
        font-family: $font-title-stack;
        font-size: 2.25rem;
        font-weight:700;
        line-height:2.45rem;
    }
    @if $fontName == "headingM"{
        font-family: $font-title-stack;
        font-size: 1.5rem;
        font-weight:700;
        line-height: 1.7rem;
    }
    @if $fontName == "headingS"{
        font-family: $font-title-stack;
        font-size: 1.25rem;
        font-weight:700;
        line-height: 1.45rem;
    }
    @if $fontName == "headingXS"{
        font-family: $font-title-stack;
        font-size: 1rem;
        font-weight:700;
        line-height: 1.2rem;
    }

    @if $fontName == "paragraphL"{
        font-family: $font-stack;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
    @if $fontName == "paragraph"{
        font-family: $font-stack;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    @if $fontName == "buttonS"{
        font-family: $font-stack;
        font-size: 1.1875rem;
        font-weight: bold;
    }
}

@mixin material($icon-name, $family:'Material Icons'){
    font-family: $family;
    content: $icon-name;
    -webkit-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
}
