/* --- Brand Colors ---------------------------- */
$color-primary: #003865;
$color-primary-medium: #008eaa;
$color-primary-light: #9bcbeb;
$color-secondary: #78be21;
$color-secondary-dark: #0d5257;
/* --- Extended Colors ---------------------------- */
$color-orange: #8d3f2b;
$color-purple: #5d295f;
$color-blue-gray: #a4bcc2;
$color-cream: #f5e1a4;
$color-gold: #ffc845;
/* --- Neutral Colors  ---------------------------- */
$color-neutral-0: #000000;
$color-neutral-20: #53565a;
$color-neutral-40: #97999b;
$color-neutral-60: #d9d9d6;
// $color-neutral-80: #f7f7f7; // changed to f8 below for accessibility and a slightly better contrast ratio when using color-secondary for links
$color-neutral-80: #f8f8f8;
$color-neutral-100: #ffffff;
/* --- Notification Colors ----------------------- */
$color-danger: #921e00;
$color-warning: #ffc845;
/*--- Link Colors  -------------------------------- */
$color-link-default:#007D96;
$color-link-hover: #003865;
$color-link-visited:#5D295F;
$color-link-active:#BD3F2B;
$color-link-reverse: #FFFFFF;
/* --- Background Colors  ---------------------------- */
//Accessibility Friendly Colors for use as bg color
$color-primary-medium-bg: #007d96; // TODO footer legal links use this color on hover
$color-primary-light-bg: #cde5f5;
$color-primary-light-hover: #b6d9f0;
$color-primary-hover:#194C74;

/* --- Top Producer Badge Colors  ---------------------------- */
//Accessibility Friendly Colors for use as bg color
$color-topProducer-Homeownership: #003865; // TODO footer legal links use this color on hover
$color-topProducer-FixUp: #4E7B15;
$color-topProducer-Placeholder1: #5D295F;
$color-topProducer-Placeholder2:#007D96;
$color-topProducer-Placeholder3:#8D3F2B;


@mixin colorScheme($colorName) {
	@if $colorName == "primary" {
		background-color: $color-primary;
		color: $color-neutral-100;
	}
	@if $colorName == "primary-medium" {
		background-color: $color-primary-medium-bg;
		color: $color-neutral-100;
	}
	@if $colorName == "primary-light" {
		background-color: $color-primary-light;
		color: $color-neutral-0;
	}
	@if $colorName == "secondary" {
		background-color: $color-primary-light-bg;
		color: $color-neutral-0;
	}
	@if $colorName == "secondary-dark" {
		background-color: $color-secondary-dark;
		color: $color-neutral-100;
	}
	@if $colorName == "light-gray" {
		background-color: $color-neutral-60;
		color: $color-neutral-0;
	}
	@if $colorName == "danger" {
		background-color: #921E00;
		color: $color-neutral-100;
	}
}

