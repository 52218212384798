@use '../index' as *;

.primaryBtn{
	color: $color-neutral-100;
    background-color: $color-primary-medium-bg;
    border: 1px solid $color-primary-medium-bg;
    padding: .5rem;
    cursor: pointer;

	&:hover{
		background-color: $color-link-hover;
		color: $color-neutral-100;
		border: 1px solid $color-primary-medium-bg;
	}
}

.secondaryBtn{
	background-color: $color-neutral-100;
    color: $color-primary;
    padding: 0.5rem;
    border: 1px solid $color-primary;
    cursor: pointer;

	&:hover{
		background-color: $color-link-hover;
    	color: $color-neutral-100;
	}
}