@use '../index' as *;
#layout-breadcrumbs {
	padding: $page-padding $page-padding 0.5rem $page-padding;
	max-width: $page-width;
	margin: 0 auto;
	ol {
		margin: 0;
		padding-left: 0;
		list-style: none;
	}

	li {
		display: inline;
	}

	li + li::before {
		display: inline-block;
		margin: 0 0.25em;
		transform: rotate(15deg);
		border-right: 0.1em solid currentcolor;
		height: 0.8em;
		content: '';
	}

	a{
		color:$color-link-hover;
		text-decoration: none;

		&:visited{
			color:$color-link-visited;
		}

		&:hover, &:active{
			text-decoration: underline;
			color:$color-link-hover;
		}

		
	}

	[aria-current='page'] {
		color: #000;
		font-weight: 700;
		text-decoration: none;
	}
}
