@use '../index' as *;
@use '../components/breadcrumbs';

.layout-shell {
	width: 100%;

	#slot-content {
		max-width: $page-width;
		margin-inline: auto;
		padding-inline: $page-padding;
		margin-bottom: $page-padding;
	}
}
