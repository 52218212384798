@use '../index'as *;

.layout-homepage {
    width: 100%;
    main {
        max-width: $page-width;
        margin: 0 auto;
        padding: 2rem $page-padding 0 $page-padding;
        gap: 2rem;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 'slotCards'
            'slotIAmWant'
            'slotContent';

        #slot-cards {
            grid-area: slotCards;
        }

        #slot-iamwant {
            grid-area: slotIAmWant
        }

        #slot-content-section {
            display: grid;
            gap: 2rem;
            grid-area: slotContent;
            grid-template-columns: 6fr 4fr;
            grid-template-areas: 'slotArticles slotMedia'
                '. slotDirectory';

            &_articles {
                grid-area: slotArticles;
            }

            &_media {
                grid-area: slotMedia;
            }

            #slot-directory-button {
                grid-area: slotDirectory;
            }
        }
    }

    #slot-info-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'slotQuote slotEvents';
        margin-top: $page-padding;

        &_quote {
            grid-area: slotQuote;
            .scs-componentgroup,.scs-row, .scs-col {
                height: 100%;
                .scs-componentgroup {
                    height: 100%;
                    .scs-componentgroup-blockquote{
                        height: 100%;
                    }
                }

            }
            .scs-componentgroup-blockquote{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &_events {
            grid-area: slotEvents;
            .scs-row{
                height: 100%;
                .scs-col{
                    height: 100%;
                    .scs-component-container{
                        height: 100%;
                        .scs-component-bounding-box{
                            height: 100%;
                            & > div{
                                height: 100%;
                                .scs-custom-component{
                                    height: 100%;
                                    .scs-component-content{
                                        height: 100%;
                                        .scs-custom-component-wrapper{
                                            height: 100%;
                                            & > div{
                                                height: 100%;
                                                .event-widget{
                                                    height: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }

    // TODO should this be mobile-large instead, the tablet breakpoint is basically the same as desktop
    @include mq(tablet, max) {
        #slot-info-section {
            grid-template-columns: 1fr;
            grid-template-areas: 'slotQuote'
                'slotEvents';
        }
    }

    @include mq(mobile-large, max) {
        main {
            #slot-content-section {
                grid-template-columns: 1fr;
                grid-template-areas: 'slotArticles'
                    'slotDirectory'
                    'slotMedia';
            }
        }
    }
}