@use '../index' as *;
@use '../components/breadcrumbs';

.layout-two-column-offset {
	width: 100%;

	#slot-content.left{
		grid-template-columns: 1fr 2fr;
		grid-template-areas: 'offsetImage maincol'
		'subNav maincol'
		'offsetOther maincol'
		'. maincol';
	}
	#slot-content.right{
		grid-template-columns: 2fr 1fr;
		grid-template-areas: 'maincol offsetImage'
		'maincol subNav'
		'maincol offsetOther'
		'maincol .';
	}
	#slot-content {
		display: grid;
		gap: $page-padding;
		padding-bottom: $page-padding;

		max-width: $page-width;
		margin: 0 auto;
		&-subnavigation{
			grid-area: subNav;
		}

		&-main{
			grid-area: maincol;
			padding-inline: $page-padding;
		}

		&-image{
			grid-area: offsetImage;
			img{
				width: fit-content;
				max-width: 100%;
			}
		}
		&-other{
			padding-inline: 0;
			grid-area: offsetOther;
			padding: $page-padding;
		}
	}

	// switch to 1 column full width when the screen hits mobile-large size
	@include mq(mobile-large, max) {
		#slot-hero{
			display: none;
		}
		#slot-content.left {
			grid-template-columns: 1fr;
			grid-template-areas:
				'subNav'
				'offsetImage'
				'maincol'
				'offsetOther';
				&-image{
					img {
						max-width: 100vw;
						border-radius: 0%;
		
					}
				}
		}
		#slot-content.right {
			grid-template-columns: 1fr;
			grid-template-areas:
				'subNav'
				'offsetImage'
				'maincol'
				'offsetOther';
				&-image{
					img {
						max-width: 100vw;
						border-radius: 0%;
		
					}
				}
		}

	}
}
