@use 'utilities/reset';
@use 'index' as *;
@forward 'layouts/shell';
@forward 'layouts/twoCols';
@forward 'layouts/threeCols';
@forward 'layouts/twoColsOffset';
@forward 'layouts/homepage';
@forward 'components/button';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	font-size: $root-font-size;
	accent-color: $color-primary;
}

select{
	appearance: none;
	padding: .25rem .25rem .25rem .5rem;
	line-height: 1.25rem;
	border-color:rgb(118,118,118);
	color:#000;
	border-radius:3px;
	border-width:1px;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
	background-position:center right .25rem;
	background-size:1rem auto;
	background-repeat: no-repeat;
	accent-color: #5d295f;
	background-color:#fff;
	&:focus{
		box-shadow: 0 0 0 .125rem #5d295f;
		outline-style: solid;
	}
}




html {
	scroll-behavior: smooth;

	body:not(.scs-edit-mode) .scs-component-bounding-box {
		padding: 0;
		-webkit-padding-before: 0;
		-webkit-padding-after: 0;
		-webkit-padding-start: 0;
		-webkit-padding-end: 0;
	}

	body {
		position: relative;
		@extend .paragraph;

		&.noScroll {
			@include mq(mobile-large, max) {
				overflow-y: hidden;
			}
		}

		overflow-x: hidden;
		overflow-wrap: break-word;

		// where has low specificity so it can be overridden by the layout
		&:not(.layout-homepage)	:where(#slot-hero) {
			max-width: $page-width;
			margin-inline: auto;
			margin-bottom: $page-padding;
		}
		[id^='slot'] {
			&.no-content {
				height: 0;
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		// MNH_Header will show the slots, after it checks for a login site property
		.scs-slot {
			display: none;
			&.showing {
				display: revert;
				*.scs-slot {
					display: revert;
				}
			}
		}
		.showing {
			display: revert;
			*.scs-slot {
				display: revert;
			}
		}
	}

	#atcb-bgoverlay {
		display: none !important;
	}

	.scs-custom-component-wrapper {
		overflow: visible !important;
	}

	.reverseLinks {
		a {
			color: $color-neutral-100;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
				color: $color-neutral-100;
			}

			&:visited {
				color: $color-neutral-100;
			}

			&:active {
				color: $color-neutral-100;
				text-decoration: none;
			}
		}
	}

	a {
		color: $color-link-default;

		&:hover {
			color: $color-link-hover;
			text-decoration: none;
		}

		&:visited {
			color: $color-link-visited;
			text-decoration: none;
		}

		&:active {
			color: $color-link-active;
			text-decoration: none;
		}
	}
}

/* font styling*/

.headingXL {
	@include fontSettings('headingXL');
}

.headingL {
	@include fontSettings('headingL');
}

.headingM {
	@include fontSettings('headingM');
}

.headingS {
	@include fontSettings('headingS');
}
.hidden-h1 {
	@include fontSettings('hidden-h1');
}

.headingXS {
	@include fontSettings('headingXS');
}

.headingXXS {
	font-family: $font-stack;
	font-size: 1rem;
	font-weight: bold;
}

.paragraphL {
	@include fontSettings('paragraphL');
}

.paragraph {
	@include fontSettings('paragraph');
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.woff2) format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Material Icons Outlined';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Outlined.woff2) format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Material Symbols Outlined';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialSymbols-Outlined.woff2) format('woff2');
	font-display: swap;
}

.material-symbols-outlined {
	font-family: 'Material Symbols Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
	font-family: 'Material Icons Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

/* shell */

.layout-shell {
	width: 100%;

	#slot-content {
		max-width: $page-width;
		margin-inline: auto;
		padding-inline: $page-padding;
	}
}

@media print {
	.alertBanner {
		display: none;
	}

	//homepage

	.iwantto-iam {
		display: none !important;
	}

	.layout-homepage {
		main {
			#slot-cards .mnh-columns {
				//have to override inline style
				--number-of-columns: 2 !important;
			}
			#slot-content-section {
				display: block;
			}
		}
		#slot-info-section {
			display: block;
		}
	}

	.layout-two-column-offset #slot-content {
		display: block !important;
	}
	//remove all floats display all block for printable main content
	main {
		div {
			float: none !important;
			display: block !important;
		}
		// remove navigation and other aside content
		#slot-content-other,
		#slot-content-image,
		#slot-content-subnavigation {
			display: none !important;
		}
	}
}
