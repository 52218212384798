@use '../index' as *;
@use '../components/breadcrumbs';

.layout-three-column-even {
	width: 100%;

	main {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: $page-padding;
		max-width: $page-width;
		margin: 0 auto;

		#slot-content-left,
		#slot-content-right,
		#slot-content-middle {
			display: inline-block;
			padding-inline: $page-padding;
		}

		// switch to 1 column full width when the screen hits tablet size
		@include mq(mobile-large, max) {
			grid-template-columns: 1fr;
		}
	}
}
